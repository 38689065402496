<template>
  <div class="index-container">
    <div class="bread"> <span @click="home()">操作台 / </span><span class="gray">客户</span></div>
    <el-row class="table-body">
      <div class="line clearfix">
        <h5 class="fl title">客户</h5>
        <div class="fr">
          <el-button type="primary" size="small" plain>新增</el-button>
        </div>
      </div>
      <div class="list">
        <el-tabs v-model="pState" @tab-click="handleClick">
          <el-tab-pane label="租车中" name="0"></el-tab-pane>
          <el-tab-pane label="未租车" name="1"></el-tab-pane>
        </el-tabs>
        <el-table
            :data="tableData"
            style="width: 100%">
          <el-table-column
              fixed
              prop="pid"
              label="序号"
              width="80">
          </el-table-column>
          <el-table-column
              prop="name"
              label="客户名"
              width="300">
          </el-table-column>
          <el-table-column
              prop="phone"
              label="手机号"
              min-width="300">
          </el-table-column>

          <el-table-column
              fixed="right"
              label="操作"
              width="150">
            <template slot-scope="scope">
              <el-button
                  type="text"
                  size="small">
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pages">
        <el-pagination
            background
            :hide-on-single-page="showPage"
            layout="prev, pager, next"
            :page-size="pageSize"
            :total="total">
        </el-pagination>
      </div>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "customer",
  data(){
    return{
      total:0, //总条数
      pageNo:1,
      pageSize:10,
      showPage:true,
      pState:0,
      tableData: [
        // {
        //   cid:1,
        //   name:"名字",//客户名称
        //   phone:"11111111111"
        // },
      ], //表格数据

      search:{
        plate:"",//搜索车牌
        head:"",//负责人
      },
      formDate:{
        cid:'',
        name:"名字",//客户名称
        phone:"11111111111"
      }, // 编辑/新增数据
    }
  },
  methods:{
    handleClick() {
    },
    home(){
      this.goHome()
    },
  }
}
</script>

<style  lang="less" scoped>
.table-body{
  background-color: #FFFFFF;
  padding:30px ;
  padding-top: 0;
  .line{
    height: 90px;
    line-height: 90px;
    .title{
      font-size: 16px;
      font-weight: 800;
      color: #333333;
    }
    .search{
      display: flex;
      align-items: center;
      align-content: center;
      justify-items: flex-end;
      .el-input{
        font-size: 12px;
        margin-right: 12px;


        .el-input__inner{
          border: none;
          background-color: #FAFAFA;
          padding-left: 20px !important;
        }
        .el-input__prefix{
          width: 40px;
          &:after{
            content: '';
            width: 1px;
            height: 15px;
            background-color: #D8D7D7;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }

    }
  }
}
</style>
